import React from 'react'
import { ConfigProvider } from 'antd'
import { useLocation, useRoutes } from 'react-router-dom'
import { Col, Row } from 'antd'
import ja_JP from 'antd/es/locale/ja_JP'
import 'moment/locale/ja'
import { includes } from 'lodash'
import TagManager from 'react-gtm-module'
import routeConfig from 'src/routes'
import Notification from './containers/Notification'
import LoadingSuspense from './components/application/LoadingSuspense'
import { useAppSelector } from './store'
import { GTMID, REACT_APP_BASE_WEB_URL } from 'src/constants/env-constant'

// import animate on scroll
import Aos from 'aos'
import 'aos/dist/aos.css'

TagManager.initialize({
  gtmId: GTMID,
  dataLayer: ['set', 'linker', { domains: [REACT_APP_BASE_WEB_URL] }],
})

function App() {
  // animate on scroll initialization
  Aos.init({
    duration: 1500,
    offset: 100,
  })

  const routes = useRoutes(routeConfig)
  const loading = useAppSelector((state) => state.ui.app.loading)
  const location = useLocation()

  React.useEffect(() => {
    if (!includes(location.hash, '#')) {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }, 10)
    }
  }, [location])

  return (
    <ConfigProvider locale={ja_JP} componentSize="middle">
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="wrapper mb-120">
          {routes}
          {loading && <LoadingSuspense />}
          <Notification />
        </Col>
      </Row>
    </ConfigProvider>
  )
}
export default App
