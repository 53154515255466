import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Card, Modal, Tag } from 'antd'
import { Plan } from 'src/entities/plan'
import { resPlans } from '../utils/medicine'
import { formatNumPrice } from 'src/utils/helper'

const ListMedicinesComponent: React.FC = () => {
  const [plans, setPlans] = useState<any[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [data, setData] = useState({
    image: '',
    title: '',
    quantity: '',
    using: '',
    ingredients: '',
    drug_effect_1: '',
    drug_effect_2: '',
  })
  const handleOpenModal = (item) => {
    setData({
      image: item.image_url,
      title: item.name,
      quantity: item.quantity,
      using: item.using,
      ingredients: item.ingredients,
      drug_effect_1: item.drug_effect_1,
      drug_effect_2: item.drug_effect_2,
    })
    setIsModalOpen(true)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    // Call api
    const newPlans = resPlans.map((item: any) => new Plan().deserialize(item))
    setPlans(newPlans)
  }, [])
  return (
    <Wrapper id="list-medicine" className="medicines">
      <HeaderStyle>
        <div className="medicines__header">
          <div className="medicines__title">処方されるお薬例</div>
          <div className="mb-30 p-20">
            「医薬品」は有効成分の効果が認められたもので、お肌の治療または予防に使用されます。市販の化粧品やサプリメントは効能効果は劣り、補助としてしか効きません。
          </div>
        </div>
      </HeaderStyle>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="p-5 Basic-modal"
      >
        <div className="medicines__drug-item text-center">
          <div className="medicines__drug-img">
            <img src={data.image} alt="" />
          </div>
          <div className="medicines__drug-name fs-16 font-weight-bold mt-2">
            {data.title}
          </div>
          <div className="quantity-using mt-3">
            <span>{data.quantity}</span>
            <span>{data.using}</span>
          </div>
          <div className="ingredients mt-2">
            <p>{data.ingredients}</p>
          </div>
          <div className="drug_effect text-left mt-2">
            <span>効果</span>
            <li>・{data.drug_effect_1}</li>
            <li>・{data.drug_effect_2}</li>
          </div>
        </div>
      </Modal>
      <div className="medicines__content">
        {plans.map((plan) => {
          return (
            <Card key={plan.id} className="medicines__item">
              <div className="medicines__title">{plan.name}</div>
              <div className="medicines__month fs-16 font-weight-bold">
                1カ月分
              </div>
              <div className="medicines__des">{plan.description}</div>
              <div className="medicines__action">
                {plan.troubles.map((item, index) => {
                  return (
                    <Tag
                      key={index}
                      color="var(--color-gurkha-dark)"
                      className="p-5 font-weight-bold"
                    >
                      {item}
                    </Tag>
                  )
                })}
              </div>
              <div className="medicines__drug mb-4">
                {plan.medicines.map((item) => {
                  return (
                    <div className="medicines__drug-item" key={item.id}>
                      <div
                        className="medicines__drug-img"
                        onClick={() => handleOpenModal(item)}
                      >
                        <img src={item.image_url} alt="" />
                      </div>
                      <div className="medicines__drug-name">{item.name}</div>
                    </div>
                  )
                })}
              </div>

              <div className="medicines__price mt-3">
                初回半額 :
                <span className="medicines__price-item">
                  ¥{formatNumPrice(plan.medicine_price_1)}
                </span>
                <span className="fs-13 cl-chestnut-rose">(税込)</span>
              </div>
              <div className="medicines__price mt-1">
                2回目以降 :
                <span className="medicines__price-item">
                  ¥{formatNumPrice(plan.medicine_price_2)}
                </span>
                <span className="fs-13 cl-chestnut-rose">(税込)</span>
              </div>
              <div className="medicines__price medicines__left mt-3">
                <p className="fs-13 text-left">
                  ※薬剤は在庫状況により代替薬に変更になる可能性があります
                </p>
                <p className="fs-13 mt-1 text-left">
                  ※3カ月以上継続定期配送プランです。
                  <br />
                  （定期配送の解約は4カ月目から可能です。）
                </p>
                <p className="fs-13 mt-1 text-left">
                  ※解約方法については
                  <a
                    href="https://faq.bioportplus.jp/docs/regular-delivery"
                    target="_blank"
                    rel="noreferrer"
                  >
                    こちら
                  </a>
                </p>
              </div>
            </Card>
          )
        })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
  text-align: center;

  .ant-tag {
    min-width: 80px;
  }

  .medicines {
    &__header {
      padding-bottom: 30px;
    }

    &__content {
      margin-top: -80px;
      padding: 0 20px;
      margin-bottom: 20px;
    }
    &__item {
      margin-top: 20px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }

    &__title {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      margin: 20px 0;
    }

    &__month {
      display: inline-block;
      background-color: #d0cfcb;
      padding: 5px 20px;
      margin-bottom: 15px;
      font-size: 14px;
    }

    &__action {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      // gap: 15px;
      margin: 20px auto;

      span {
        background-color: #f16576 !important;
      }

      button {
        width: 25% !important;
        border-radius: unset;
        padding: 10px;
        height: 40px !important;
        font-weight: bold;
      }
    }

    &__drug {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 15px;

      img {
        width: 100%;
        margin-bottom: 10px;
        cursor: pointer;
      }

      &-img {
        border-radius: 50%;
        margin-bottom: 10px;
      }

      &-item {
        width: 30%;
      }

      &-name {
        font-size: 13px;
        text-decoration-line: underline;
      }
    }

    &__price {
      &-item {
        font-size: 24px;
        font-weight: bold;
        margin-left: 10px;
        color: var(--color-chestnut-rose);
      }
    }

    &__left {
      padding: 0 20px;
      font-weight: 600;
      & a {
        color: var(--color-chestnut-rose);
        text-decoration: underline;
      }
    }

    &__des {
      white-space: pre-wrap;
    }
  }
`
const HeaderStyle = styled.div`
  background-color: rgb(45, 167, 224, 0.4);
  padding-top: 80px;
`
export default ListMedicinesComponent
