import React from 'react'
import styled from 'styled-components'

import logo from 'src/assets/images/landing/logo.svg'
import {
  REACT_APP_BASE_SHEEP_URL,
  REACT_APP_BASE_WEB_URL,
} from 'src/constants/env-constant'

const CONTACT_LINK = '#contact'

const FooterComponent: React.FC = () => {
  const footerLink = [
    {
      name: '会社概要',
      link: `${REACT_APP_BASE_SHEEP_URL}/about`,
    },
    {
      name: 'プライバシーポリシー',
      link: `${REACT_APP_BASE_WEB_URL}/policy`,
    },
    {
      name: '利用規約',
      link: `${REACT_APP_BASE_WEB_URL}/terms`,
    },
    {
      name: '特定商法取引法に基づく表記',
      link: `${REACT_APP_BASE_WEB_URL}/legal-notice`,
    },
    {
      name: 'お問い合わせ',
      link: CONTACT_LINK,
    },
  ]
  return (
    <Wrapper>
      <div className="footer-head">
        <img src={logo} alt="" />
      </div>
      <div className="footer-content">
        {footerLink.map((item) => {
          let link = item.link

          if (item.link === CONTACT_LINK) {
            link = `/${CONTACT_LINK}`

            return (
              <p
                key={item.link}
                onClick={() => window.location.replace(link)}
                className="c-pointer"
              >
                {item.name}
              </p>
            )
          }

          return (
            <p
              key={item.link}
              onClick={() => window.location.assign(item.link)}
              className="c-pointer"
            >
              {item.name}
            </p>
          )
        })}
      </div>
      <p className="text-center">© BIOPORT+ All Rights Reserved.</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: rgb(45, 167, 224, 0.4);

  .footer-head {
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    margin: 50px auto 30px;

    img {
      height: 40px;
    }
  }

  .footer-content {
    text-align: center;
    font-weight: bold;
    margin: 50px auto;

    p {
      padding: 20px;
    }
  }
`

export default FooterComponent
