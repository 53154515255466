import React, { useEffect } from 'react'
import ButtonCustom from 'src/components/form/Button'
import { BASE_WEB_CLINICS_URL } from 'src/utils/constant'
import styled from 'styled-components'
import bannerFamaleDoctor from 'src/assets/images/banner-famale-doctor.png'

const ButtonBooking = () => {
  useEffect(() => {
    document.body.addEventListener('touchmove', scrollHandle)
    window.addEventListener('scroll', scrollHandle)
  })

  const scrollHandle = () => {
    const bookingElement: any = document.getElementById('booking-online')
    const { scrollTop, scrollHeight } = document.body
    const { innerHeight } = window

    if (scrollTop + innerHeight >= scrollHeight) {
      bookingElement.classList.add('hide')
      return
    }
    bookingElement.classList.remove('hide')
  }

  return (
    <Wrapper>
      <div id="booking-online" className="px-2">
        <img
          className="img-doctor"
          src={bannerFamaleDoctor}
          alt="banner-doctor"
        />
        <ButtonCustom
          className="btn-default btn-gurkha fs-16"
          loading={false}
          onClick={() => window.open(`${BASE_WEB_CLINICS_URL}/hj`)}
          name="オンライン診察を予約する"
          showIconRight={true}
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  #booking-online {
    width: 100%;
    height: 100px;
    background-color: #ffe9e8;
    opacity: 1;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1001;
    transition: opacity 0.3s linear;
    flex: 0 0 50%;
    max-width: 50%;
    left: 25%;
    z-index: 2;
    display: flex;

    .ant-btn,
    p {
      letter-spacing: 0.2em;
    }

    .img-doctor {
      width: 86px;
      height: 91px;
      margin-top: 9px;
    }

    .btn-gurkha {
      background-color: #f16576;
      margin-right: 1.5rem !important;
    }

    @media (max-width: 991px) {
      max-width: 100%;
      left: 0;
    }

    @media (max-width: 480px) {
      .fs-16 {
        font-size: 14px !important;
      }
    }

    @media (max-width: 380px) {
      .fs-16 {
        font-size: 12px !important;
      }
    }
  }

  .hide {
    opacity: 0 !important;
  }
`

export default ButtonBooking
