import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { Drawer } from 'antd'

// components
import ButtonCustom from 'src/components/form/Button'

// images
import { BASE_WEB_CLINICS_URL } from 'src/utils/constant'
import { clinicsData } from 'src/containers/Top/utils/clinics'
import DefaultLogoIcon from 'src/assets/images/landing/logo.svg'

export type LPMenuType = {
  name: string
  link: string
  id: string
}

export type LPMenuProps = {
  hasMenu?: boolean
}

const Index = (props: LPMenuProps) => {
  const [open, setOpen] = useState(false)
  const { hasMenu = true } = props

  const menus: LPMenuType[] = [
    {
      name: 'BIOPORT+とは',
      link: '#about-bioport',
      id: 'about-bioport',
    },
    {
      name: '処方プラン例',
      link: '#list-medicine',
      id: 'list-medicine',
    },
    {
      name: '利用方法',
      link: '#using',
      id: 'using',
    },
    {
      name: '提携クリニック紹介',
      link: '#clinic',
      id: 'clinic',
    },
    {
      name: 'よくある質問',
      link: '#faq',
      id: 'faq',
    },
    {
      name: 'お問い合わせ',
      link: '#contact',
      id: 'contact',
    },
  ]
  const plClinic = clinicsData.lpClinics.find(
    (plClinic) => plClinic.uuid === 'hj',
  )

  return (
    <Wrapper className="menu-wrp">
      <div className="menu d-flex justify-content-start">
        <img
          width={plClinic?.logo ? '240px' : '170px'}
          onClick={() => {}}
          src={plClinic?.logo ?? DefaultLogoIcon}
          alt=""
        />
      </div>

      {hasMenu && (
        <div
          className={`nav-icon ${open ? 'open' : ''}`}
          onClick={() => setOpen(!open)}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}

      <div className="modal-menu">
        <Drawer
          className="modal-menu"
          placement="right"
          open={open}
          headerStyle={{ display: 'none' }}
        >
          <div className="menu mt-100">
            {menus.map((item: LPMenuType, index) => {
              return (
                <Fragment key={index}>
                  <div
                    onClick={() => {
                      setOpen(false)
                      window.location.replace(item.link)
                    }}
                  >
                    <div className="menu-item flex-auto d-flex align-items-center justify-content-center c-pointer">
                      <p className="menu-name cl-black text-center">
                        {item.name}
                      </p>
                    </div>
                  </div>
                </Fragment>
              )
            })}
          </div>
          <div className="bg-spring-wood modal-menu__footer">
            <p className="text-center cl-chestnut-rose font-weight-bold mt-10 fs-18">
              ＼お悩みを医師に無料相談／
            </p>
            <ButtonCustom
              className="btn-default btn-gurkha fs-16"
              loading={false}
              onClick={() => window.open(`${BASE_WEB_CLINICS_URL}/hj`)}
              name="オンライン診察を予約する"
              showIconRight={true}
            />
          </div>
        </Drawer>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .menu {
    position: absolute;
    padding: 20px;
    /* height: 100px; */
    z-index: 2;
    width: 100%;
    left: 0;
    top: 0;

    img {
      height: auto;
    }
  }

  .modal-menu {
    display: none;
  }

  .nav-icon {
    right: 20px;
    position: fixed;
    top: 25px;
    width: 32px;
    z-index: 1001;
    height: 22px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: var(--color-black);
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0px;
    }

    span:nth-child(3) {
      top: 20px;
    }

    span:nth-child(2) {
      top: 10px;
    }

    &.open {
      span:nth-child(1) {
        top: 18px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }

      span:nth-child(2) {
        opacity: 0;
        left: -60px;
      }

      span:nth-child(3) {
        top: 18px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
  }

  @media (min-width: 991px) {
    .nav-icon {
      right: 27%;
    }
  }
`

export default Index
