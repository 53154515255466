import React from 'react'
import styled from 'styled-components'
import drugImg1 from 'src/assets/images/drug-1.svg'
import drugImg2 from 'src/assets/images/drug-2.svg'
import drugImg3 from 'src/assets/images/drug-3.svg'
import triangleImg from 'src/assets/images/drug-triangle.svg'
import xImg from 'src/assets/images/drug-x.svg'
import circleImg from 'src/assets/images/drug-circle.svg'
import circleDoubleImg from 'src/assets/images/drug-circle-red.svg'

const DrugsComponent: React.FC = () => {
  const drugs = [
    {
      title: '化粧品',
      detail: [
        {
          img_url: drugImg1,
          content: '',
          label: '使用目的',
          is_first: true,
        },
        {
          img_url: triangleImg,
          content: '健康を保つ',
          label: '使用目的',
        },
        {
          img_url: xImg,
          content: 'なし',
          label: '',
        },
      ],
    },
    {
      title: 'サプリメント',
      detail: [
        {
          img_url: drugImg2,
          content: '',
          label: '使用目的',
          is_first: true,
        },
        {
          img_url: circleImg,
          content: '予防',
          label: '効能効果',
        },
        {
          img_url: triangleImg,
          content: '医薬品ほど効果 \n 効能をうたえない',
          label: '',
          classes: 'fs-10 line-height-18',
        },
      ],
    },
    {
      title: '医薬品',
      is_last: true,
      detail: [
        {
          img_url: drugImg3,
          content: '',
          label: '使用目的',
          is_first: true,
        },
        {
          img_url: circleDoubleImg,
          content: '治療・予防',
          label: '効能効果',
        },
        {
          img_url: circleDoubleImg,
          content: 'あり',
          label: '',
        },
      ],
    },
  ]

  return (
    <Wrapper className="px-4 pb-40">
      <h4 className="lp-page__header" data-aos="fade-down">
        医療用医薬品とは？
      </h4>
      <div className="px-2 mb-30" data-aos="fade-up" data-aos-delay="500">
        <p className="fs-12 mt-35">
          「医薬品」は有効成分の効果が認められたもので、治療または予防に使用されます。
          <br />
          市販の化粧品やサプリメントの効果効能は医薬品と異なり、実証されていることが少ない為、補助として利用されることが多いです。
        </p>

        <h4 className="font-weight-bold fs-15 mt-3">
          BIOPORT+は医師の診察のもと <br />
          お悩みに合った医薬品をお届けします
        </h4>

        <div className="drug__table mt-20">
          <p className="text-center drug-type">一般的なスキンケア用品</p>
          <div className="d-flex">
            {drugs.length > 0 &&
              drugs.map((item, index) => {
                return (
                  <div
                    className={`drug__item ${item.is_last ? 'last-item' : ''}`}
                    key={index}
                  >
                    <h4 className="drug__item-header">{item.title}</h4>
                    {item.detail.length > 0 &&
                      item.detail.map((detail, i) => {
                        return (
                          <div
                            className={`drug__item-detail ${
                              detail.is_first ? 'first-item' : ''
                            }`}
                            key={i}
                          >
                            <div className="drug__item-wrp">
                              <img src={detail.img_url} alt="" />
                              {detail.content && (
                                <p
                                  className={`${
                                    detail.classes ? detail.classes : ''
                                  }`}
                                >
                                  {detail.content}
                                </p>
                              )}
                            </div>
                            {detail.label && (
                              <label className="mt-10">{detail.label}</label>
                            )}
                          </div>
                        )
                      })}
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: rgb(255, 212, 209, 0.5);

  p {
    line-height: 25px;
  }

  .line-height-18 {
    line-height: 18px;
  }

  .drug {
    &__table {
      background-color: #fff;
      position: relative;
    }

    &__item {
      width: calc(100% / 3);

      &-detail {
        padding: 10px;
        border-right: 1px solid var(--color-quill-gray);
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-wrp {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          text-align: center;
          transform: translate(-50%, -50%);
          white-space: pre-wrap;
        }

        img {
          width: auto;
          height: 50px;
        }
      }

      &-header {
        text-align: center;
        padding: 5px;
        border: 1px solid var(--color-quill-gray);
        border-left: 0;
        font-size: 13px;
        color: #797770;
      }

      label {
        font-size: 11px;
        background: #f7f3ee;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        color: #797770;
        padding: 3px 10px;
        line-height: 16px;
      }
    }
  }

  .drug-type {
    width: calc((100% / 3) * 2);
    padding: 5px 0;
    font-size: 12px;
  }

  .first-item {
    img {
      height: 42px;
    }
  }

  .last-item {
    border: 2px solid #2ea7e0;
    position: absolute;
    top: -10px;
    right: 0;
    background: #fff;

    .drug__item {
      &-header {
        border: none;
        background-color: #2ea7e0;
        color: #fff;
        font-size: 20px;
        line-height: 25px;
        padding: 25px 10px;
      }
    }

    label {
      background-color: #2ea7e0;
      color: #fff;
    }
  }
`

export default DrugsComponent
