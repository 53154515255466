import medicine_1 from 'src/assets/images/medicine/medicine_1.svg'
import medicine_2 from 'src/assets/images/medicine/medicine_2.svg'
import medicine_3 from 'src/assets/images/medicine/medicine_3.svg'
import medicine_4 from 'src/assets/images/medicine/medicine_4.svg'
import medicine_5 from 'src/assets/images/medicine/medicine_5.svg'
import medicine_6 from 'src/assets/images/medicine/medicine_6.svg'
import medicine_7 from 'src/assets/images/medicine/medicine_7.svg'
import medicine_8 from 'src/assets/images/medicine/medicine_8.svg'

export const medicine1 = {
  id: 1,
  name: 'シーピー',
  image_url: medicine_1,
  quantity: '60包',
  using: '1日2回',
  ingredients: '主成分 : アスコルビン酸(ビタミンC)',
  drug_effect_1: 'メラニンの生成を抑制 ',
  drug_effect_2: 'シミ、シワ予防',
}
export const medicine2 = {
  id: 2,
  name: 'ノイロビタン',
  image_url: medicine_2,
  quantity: '60錠',
  using: '1日2回',
  ingredients: '主成分 : オクトチアミン \n ピリドキシン塩酸塩 等',
  drug_effect_1: '肌荒れの改善',
  drug_effect_2: 'ニキビの治療',
}
export const medicine3 = {
  id: 3,
  name: 'ハイチオール',
  image_url: medicine_3,
  quantity: '60錠',
  using: '1日2回',
  ingredients: '主成分 : L-システィン',
  drug_effect_1: 'ターンオーバーを正常化',
  drug_effect_2: 'シミ、そばかすを改善',
}
export const medicine4 = {
  id: 4,
  name: 'ユベラ',
  image_url: medicine_4,
  quantity: '60錠',
  using: '1日2回',
  ingredients: '主成分 : トコフェロール酢酸エステル',
  drug_effect_1: '皮膚の代謝を促進',
  drug_effect_2: '抗酸化作用',
}
export const medicine5 = {
  id: 5,
  name: 'トランサミン',
  image_url: medicine_5,
  quantity: '60錠',
  using: '1日2回',
  ingredients: '主成分 : トラネキサム酸',
  drug_effect_1: '肝斑の改善',
  drug_effect_2: 'メラニンの生成を抑制',
}
export const medicine6 = {
  id: 6,
  name: 'ハイドロキノン4％クリーム',
  image_url: medicine_6,
  quantity: '20mg',
  using: '1日1回夜',
  ingredients: '主成分 : ハイドロキノン',
  drug_effect_1: '高い美白効果',
  drug_effect_2: 'メラニン自体を減少させる',
}
export const medicine7 = {
  id: 7,
  name: 'ディフィリン',
  image_url: medicine_7,
  quantity: '1本(15g)',
  using: '1日1回',
  ingredients: '主成分 : アダパレン',
  drug_effect_1: '毛穴のつまりを改善',
  drug_effect_2: 'ニキビの発生を防ぐ',
}
export const medicine8 = {
  id: 8,
  name: 'トレチノイン0.05%クリーム',
  image_url: medicine_8,
  quantity: '20mg',
  using: '1日1回夜',
  ingredients: '主成分 : トレチノイン',
  drug_effect_1: 'ターンオーバーを促進',
  drug_effect_2: 'ニキビ痕改善',
}

export const resPlans = [
  {
    id: 1,
    name: '肌ケアセット①',
    description: 'ゆらぎのない肌へ \n お肌の基礎力を高めて肌トラブルを抑える',
    medicines: [medicine1, medicine2, medicine3],
    troubles: ['シミ', 'くすみ', 'シワ'],
    medicine_price_1: 2740,
    medicine_price_2: 5480,
  },
  {
    id: 2,
    name: '肌ケアセット②',
    description: 'ゆらぎのない肌へ \n お肌の基礎力を高めて肌トラブルを抑える',
    medicines: [medicine1, medicine2, medicine3, medicine4],
    troubles: ['シミ', 'くすみ', 'たるみ'],
    medicine_price_1: 3092,
    medicine_price_2: 6184,
  },
  {
    id: 3,
    name: '美白セット①',
    description:
      'シミ、肝斑、そばかす等、顔全体のくすみが気になる方へ \n 内側からケアする',
    medicines: [medicine1, medicine3, medicine4, medicine5],
    troubles: ['シミ', 'そばかす', 'くすみ'],
    medicine_price_1: 3968,
    medicine_price_2: 7936,
  },
  {
    id: 4,
    name: '美白セット②',
    description:
      'シミ、肝斑、そばかす等、顔全体のくすみが気になる方へ \n 内側と外側からアプローチし、より透明感のあるお肌へ',
    medicines: [medicine1, medicine3, medicine5, medicine6],
    troubles: ['シミ', '肝斑', 'そばかす'],
    medicine_price_1: 6526,
    medicine_price_2: 13052,
  },
  {
    id: 5,
    name: 'ニキビケアセット',
    description:
      'ニキビでお悩みの方へ \n 毛穴のつまりを改善し、ニキビの発生や進行を防ぎます',
    medicines: [medicine1, medicine2, medicine3, medicine4, medicine7],
    troubles: ['ニキビ', '毛穴', '美肌'],
    medicine_price_1: 4190,
    medicine_price_2: 8380,
  },
  {
    id: 6,
    name: 'ニキビ痕セット',
    description:
      'ニキビ跡が気になる方へ \n ターンオーバーを正常化し、肌本来の機能を回復します',
    medicines: [
      medicine1,
      medicine3,
      medicine4,
      medicine5,
      medicine6,
      medicine8,
    ],
    troubles: ['ニキビ', 'ニキビ痕', 'シミ'],
    medicine_price_1: 9698,
    medicine_price_2: 19396,
  },
]
