import React from 'react'
import { Outlet } from 'react-router-dom'
import ButtonBooking from 'src/components/layout/Header/components/ButtonBooking'

const GuestLayout: React.FC = () => {
  return (
    <>
      <Outlet />
      <ButtonBooking />
    </>
  )
}

export default GuestLayout
