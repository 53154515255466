// import { PlanInterface, PlanResponseInterface } from 'src/interfaces/plan'

// export default class Plan implements PlanInterface {
//   private props: PlanResponseInterface

//   constructor(props: PlanResponseInterface) {
//     this.props = props
//   }

//   get id() {
//     return this.props.id
//   }

//   get name() {
//     return this.props.name
//   }

//   get image_url() {
//     return this.props.image_url
//   }

//   get price_after() {
//     return this.props.price_after
//   }

//   get price_first() {
//     return this.props.price_first
//   }

//   get cycle() {
//     return this.props.cycle
//   }

//   get description() {
//     return this.props.description
//   }

//   get about_plan() {
//     return this.props.about_plan
//   }

//   get detail() {
//     return this.props.detail
//   }

//   get auto_suggestion_status() {
//     return this.props.auto_suggestion_status
//   }

//   get is_shampoo_subscription() {
//     return this.props.is_shampoo_subscription
//   }

//   get karte_status() {
//     return this.props.karte_status
//   }
// }

import { Deserializable } from 'src/interfaces/deserializable'
import { PlanInterface } from 'src/interfaces/plan'
import { Medicine } from './medicine'
import { formatNumPrice } from 'src/utils/helper'

export class Plan implements Deserializable<any>, PlanInterface {
  id: number = 0
  name: string = ''
  image_url: string = ''
  description: string = ''
  medicines: Medicine[] = []
  price: number = 0
  troubles: String[] = []

  constructor() {
    //
  }
  medicine_price_1!: number
  medicine_price_2!: number

  get priceString() {
    return '¥' + formatNumPrice(this.price)
  }

  deserialize(input: Partial<PlanInterface>): Plan {
    Object.assign(this, input)
    if (input.medicines && input.medicines.length) {
      this.medicines = input.medicines.map((item) =>
        new Medicine().deserialize(item),
      )
    }
    return this
  }
}
