import { contactTypes } from './contactType'
import { ContactInterface } from '../../../interfaces/contact'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { submitContact } from 'src/useCase/contact'
import { useAppDispatch } from 'src/store'
import uiActions from 'src/store/ui/notification'

export const useContact = () => {
  const dispatch = useAppDispatch()

  const defaultValues: ContactInterface = {
    email: '',
    name: '',
    contact_type: contactTypes[0].value,
    content: '',
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('入力してください。')
      .email('メールアドレスを正しく入力してください')
      .min(3, 'メールアドレスは30文字以下で入力してください')
      .max(100, 'メールアドレスは100文字以下で入力してください'),
    name: Yup.string()
      .required('入力してください。')
      .max(255, 'お名前は30文字以下で入力してください'),
    contact_type: Yup.string(),
    content: Yup.string()
      .required('入力してください。')
      .max(1000, 'お問い合わせ内容は30文字以下で入力してください'),
  })

  const onSubmit = async (data) => {
    await dispatch(submitContact({ params: data, form: form })).then(() =>
      dispatch(
        uiActions.onNotification({
          type: 'success',
          message: '送信されました。',
        }),
      ),
    )
  }

  const form = useForm<ContactInterface>({
    defaultValues,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  return {
    form,
    onSubmit,
  }
}
